import { createReducer } from "@reduxjs/toolkit";
import { getNews, getNewsEN, getNewsTH } from "@/features/news/actions";
import { newsState } from "@/models/news.model";

const initialState: newsState = {
  data: {
    news_id: "",
    topic: "",
    post_date: "",
    status: "",
    detail: "",
    arr: "",
    year: "",
    filename: "",
    original_name: "",
    created_at: "",
  },
  dataEN: {
    news_id: "",
    topic: "",
    post_date: "",
    status: "",
    detail: "",
    arr: "",
    year: "",
    filename: "",
    original_name: "",
    created_at: "",
  },
  dataTH: {
    news_id: "",
    topic: "",
    post_date: "",
    status: "",
    detail: "",
    arr: "",
    year: "",
    filename: "",
    original_name: "",
    created_at: "",
  },
};

export const newsFrontReducer = createReducer(initialState, (builder) => {
  builder.addCase(getNews.fulfilled, (state, action) => {
    state.data = action.payload;
  });

  builder.addCase(getNewsEN.fulfilled, (state, action) => {
    state.dataEN = action.payload;
  });

  builder.addCase(getNewsTH.fulfilled, (state, action) => {
    state.dataTH = action.payload;
  });
});

export default newsFrontReducer;
