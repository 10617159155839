/* eslint-disable @next/next/inline-script-id */
import type { AppProps } from "next/app";
import { wrapper } from "@/store/store";
import React from "react";
import { appDispatch, appSelector } from "@/store/hooks";
import { getSession } from "@/features/admin/login";
import { Provider, useStore } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Script from "next/script";
// add bootstrap css
// import "bootstrap/dist/css/bootstrap.css";
import "@/public/static/css/stylesheet.css";

//slide
import "../public/static/css/embla.css";

// function MyApp({ Component, ...rest }: AppProps) {
//   const { props } = wrapper.useWrappedStore(rest);
//   const store: any = useStore();
//   const dispatch = appDispatch();
//   React.useEffect(() => {
//     dispatch(getSession());
//   }, [dispatch]);
//   return (
//     <Provider store={store}>
//       <PersistGate persistor={store.__persistor} loading={null}>
//         <Component {...props.pageProps} />
//       </PersistGate>
//     </Provider>
//   );
// }

function MyApp({ Component, pageProps }: AppProps) {
  const store: any = useStore();
  const dispatch = appDispatch();
  // React.useEffect(() => {
  //   dispatch(getSession());
  // }, [dispatch]);
  return (
    <Provider store={store}>
      {/* <PersistGate persistor={store.__persistor} loading={null}> */}
      <>
        <Script>
          {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MZ697FNQ');
      `}
        </Script>
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-WJJLLTGJB4"
        ></Script>

        <Script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'G-WJJLLTGJB4');
      `}
        </Script>
      </>
      <Component {...pageProps} />
      {/* </PersistGate> */}
    </Provider>
  );
}

export default wrapper.withRedux(MyApp);
