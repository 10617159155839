import { FabProps } from "@mui/material";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setCookie, getCookie, deleteCookie } from "cookies-next";
import Router from "next/router";

// News JP
export const getNews = createAsyncThunk(
  "newsfront/get",
  async (keyword?: string): Promise<any> => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    if (keyword) {
      const response = await axios.get(`${url}/v1/news/get?keyword=${keyword}`);
      return response.data.data;
    } else {
      const response = await axios.get(`${url}/v1/news/select`);
      return response.data.data;
    }
  }
);

export const getNewsEN = createAsyncThunk(
  "newsfront/en/get",
  async (keyword?: string): Promise<any> => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    if (keyword) {
      const response = await axios.get(
        `${url}/v1/news/en/get?keyword=${keyword}`
      );
      return response.data.data;
    } else {
      const response = await axios.get(`${url}/v1/news/en/select`);
      return response.data.data;
    }
  }
);

export const getNewsTH = createAsyncThunk(
  "newsfront/th/get",
  async (keyword?: string): Promise<any> => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    if (keyword) {
      const response = await axios.get(
        `${url}/v1/news/th/get?keyword=${keyword}`
      );
      return response.data.data;
    } else {
      const response = await axios.get(`${url}/v1/news/th/select`);
      return response.data.data;
    }
  }
);
